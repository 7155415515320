import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Alumno = () => import('@/entities/alumno/alumno.vue');
// prettier-ignore
const AlumnoUpdate = () => import('@/entities/alumno/alumno-update.vue');
// prettier-ignore
const AlumnoSearch = () => import('@/entities/alumno/alumno-search.vue');
// prettier-ignore
const AlumnoBuscar = () => import('@/entities/alumno/alumno-buscar.vue');
// prettier-ignore
const AlumnoDetails = () => import('@/entities/alumno/alumno-details.vue');
// prettier-ignore
const Pago = () => import('@/entities/pago/pago.vue');
// prettier-ignore
const PagoCobrar = () => import('@/entities/pago/pago-cobrar.vue');
// prettier-ignore
const PagoUpdate = () => import('@/entities/pago/pago-update.vue');
// prettier-ignore
const PagoDetails = () => import('@/entities/pago/pago-details.vue');
// prettier-ignore

const PagoAlumno = () => import('@/entities/pago/pago-alumno.vue');
// prettier-ignore

const Comision = () => import('@/entities/comision/comision.vue');
// prettier-ignore
const ComisionUpdate = () => import('@/entities/comision/comision-update.vue');
// prettier-ignore
const ComisionDetails = () => import('@/entities/comision/comision-details.vue');
// prettier-ignore
const Contacto = () => import('@/entities/contacto/contacto.vue');
// prettier-ignore
const ContactoUpdate = () => import('@/entities/contacto/contacto-update.vue');
// prettier-ignore
const ContactoDetails = () => import('@/entities/contacto/contacto-details.vue');
// prettier-ignore
const Cuota = () => import('@/entities/cuota/cuota.vue');
// prettier-ignore
const CuotaUpdate = () => import('@/entities/cuota/cuota-update.vue');
// prettier-ignore
const CuotaDetails = () => import('@/entities/cuota/cuota-details.vue');
// prettier-ignore
const Responsable = () => import('@/entities/responsable/responsable.vue');
// prettier-ignore
const ResponsableUpdate = () => import('@/entities/responsable/responsable-update.vue');
// prettier-ignore
const ResponsableDetails = () => import('@/entities/responsable/responsable-details.vue');
// prettier-ignore
const Configuracion = () => import('@/entities/configuracion/configuracion.vue');
// prettier-ignore
const ConfiguracionUpdate = () => import('@/entities/configuracion/configuracion-update.vue');
// prettier-ignore
const ConfiguracionDetails = () => import('@/entities/configuracion/configuracion-details.vue');
// prettier-ignore
const MedioPago = () => import('@/entities/medio-pago/medio-pago.vue');
// prettier-ignore
const MedioPagoUpdate = () => import('@/entities/medio-pago/medio-pago-update.vue');
// prettier-ignore
const MedioPagoDetails = () => import('@/entities/medio-pago/medio-pago-details.vue');
// prettier-ignore
const ComisionNew = () => import('@/entities/comision-new/comision-new.vue');
// prettier-ignore
const ComisionNewUpdate = () => import('@/entities/comision-new/comision-new-update.vue');
// prettier-ignore
const ComisionNewDetails = () => import('@/entities/comision-new/comision-new-details.vue');
// prettier-ignore
const PagoNew = () => import('@/entities/pago-new/pago-new.vue');
// prettier-ignore
const PagoNewUpdate = () => import('@/entities/pago-new/pago-new-update.vue');
// prettier-ignore
const PagoNewDetails = () => import('@/entities/pago-new/pago-new-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'alumno',
      name: 'Alumno',
      component: Alumno,
      meta: { authorities: [Authority.USER, Authority.CONTABLE] },
    },
    {
      path: 'alumno/new',
      name: 'AlumnoCreate',
      component: AlumnoUpdate,
      meta: { authorities: [Authority.USER, Authority.CONTABLE] },
    },
    {
      path: 'alumno/search',
      name: 'AlumnoSearch',
      component: AlumnoSearch,
    },
    {
      path: 'alumno/buscar',
      name: 'AlumnoBuscar',
      component: AlumnoBuscar,
    },
    {
      path: 'alumno/:alumnoId/pagos',
      name: 'PagoAlumno',
      component: PagoAlumno,
    },
    {
      path: 'alumno/:alumnoId/edit',
      name: 'AlumnoEdit',
      component: AlumnoUpdate,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'alumno/:alumnoId/view',
      name: 'AlumnoView',
      component: AlumnoDetails,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    /* {
      path: 'pago',
      name: 'Pago',
      component: Pago,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'pago/new',
      name: 'PagoCreate',
      component: PagoUpdate,
      meta: { authorities: [Authority.USER, Authority.CONTABLE] },
    },
    {
      path: 'pago/:pagoId/edit',
      name: 'PagoEdit',
      component: PagoUpdate,
      meta: { authorities: [Authority.USER, Authority.CONTABLE] },
    },
    {
      path: 'pago/:alumnoId/:cuotaId/cobrar',
      name: 'PagoCobrar',
      component: PagoCobrar,
      meta: { authorities: [Authority.CONTABLE, Authority.COBRADOR] },
    },
    {
      path: 'pago/:pagoId/view',
      name: 'PagoView',
      component: PagoDetails,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'comision',
      name: 'Comision',
      component: Comision,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'comision/new',
      name: 'ComisionCreate',
      component: ComisionUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'comision/:comisionId/edit',
      name: 'ComisionEdit',
      component: ComisionUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'comision/:comisionId/view',
      name: 'ComisionView',
      component: ComisionDetails,
      meta: { authorities: [Authority.ADMIN, Authority.CONTABLE, Authority.USER] },
    }, */
    {
      path: 'contacto',
      name: 'Contacto',
      component: Contacto,
      meta: { authorities: [Authority.ADMIN, Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'contacto/new',
      name: 'ContactoCreate',
      component: ContactoUpdate,
    },
    {
      path: 'contacto/:contactoId/edit',
      name: 'ContactoEdit',
      component: ContactoUpdate,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'contacto/:contactoId/view',
      name: 'ContactoView',
      component: ContactoDetails,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'cuota',
      name: 'Cuota',
      component: Cuota,
      meta: { authorities: [Authority.ADMIN, Authority.USER] },
    },
    {
      path: 'cuota/new',
      name: 'CuotaCreate',
      component: CuotaUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.USER] },
    },
    {
      path: 'cuota/:cuotaId/edit',
      name: 'CuotaEdit',
      component: CuotaUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.USER] },
    },
    {
      path: 'cuota/:cuotaId/view',
      name: 'CuotaView',
      component: CuotaDetails,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'responsable',
      name: 'Responsable',
      component: Responsable,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'responsable/new',
      name: 'ResponsableCreate',
      component: ResponsableUpdate,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'responsable/:responsableId/edit',
      name: 'ResponsableEdit',
      component: ResponsableUpdate,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'responsable/:responsableId/view',
      name: 'ResponsableView',
      component: ResponsableDetails,
      meta: { authorities: [Authority.CONTABLE, Authority.USER] },
    },
    {
      path: 'configuracion',
      name: 'Configuracion',
      component: Configuracion,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'configuracion/new',
      name: 'ConfiguracionCreate',
      component: ConfiguracionUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'configuracion/:configuracionId/edit',
      name: 'ConfiguracionEdit',
      component: ConfiguracionUpdate,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'configuracion/:configuracionId/view',
      name: 'ConfiguracionView',
      component: ConfiguracionDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'medio-pago',
      name: 'MedioPago',
      component: MedioPago,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'medio-pago/new',
      name: 'MedioPagoCreate',
      component: MedioPagoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'medio-pago/:medioPagoId/edit',
      name: 'MedioPagoEdit',
      component: MedioPagoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'medio-pago/:medioPagoId/view',
      name: 'MedioPagoView',
      component: MedioPagoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'comision-new',
      name: 'ComisionNew',
      component: ComisionNew,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'comision-new/new',
      name: 'ComisionNewCreate',
      component: ComisionNewUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'comision-new/:comisionNewId/edit',
      name: 'ComisionNewEdit',
      component: ComisionNewUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'comision-new/:comisionNewId/view',
      name: 'ComisionNewView',
      component: ComisionNewDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pago-new',
      name: 'PagoNew',
      component: PagoNew,
      meta: { authorities: [Authority.USER, Authority.CONTABLE] },
    },
    {
      path: 'pago-new/new',
      name: 'PagoNewCreate',
      component: PagoNewUpdate,
      meta: { authorities: [Authority.USER, Authority.CONTABLE] },
    },
    {
      path: 'pago-new/:pagoNewId/edit',
      name: 'PagoNewEdit',
      component: PagoNewUpdate,
      meta: { authorities: [Authority.USER, Authority.CONTABLE] },
    },
    {
      path: 'pago-new/:pagoNewId/view',
      name: 'PagoNewView',
      component: PagoNewDetails,
      meta: { authorities: [Authority.USER, Authority.CONTABLE] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
