var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "justify-content-right" }, [
      _c(
        "div",
        {
          staticClass: "logo",
          attrs: {
            right: "",
            href: "https://www.unlp.edu.ar",
            target: "_blank",
          },
        },
        [_c("span", { staticClass: "logo3-img" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }