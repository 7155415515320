var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      staticClass: "jh-navbar",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
        [_c("span", { staticClass: "logo-img" })]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-brand",
        {
          staticClass: "logo",
          attrs: { href: "https://www.bba.unlp.edu.ar", target: "_blank" },
        },
        [_c("span", { staticClass: "logo2-img" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }